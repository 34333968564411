import { AxiosApi } from "./axios.instance";


const postChangeLanguage = async (lang: "ru" | "en") => {
    try {
        const {data} = await AxiosApi.post<{
            result: boolean;
        }>("/change_language", {
            lang
        })
        if(data.result) {
            window.location.reload();
        }
    } catch (err) {
        console.log(err)
    }
};
export const LanguageSwitcher = () => {

    const btn = document.querySelector("[data-button=language-switch]");
    if(!btn) return;
    (btn as HTMLButtonElement).addEventListener('click', function () {
        const lang = this.dataset.currLanguage as 'ru' | 'en';
        postChangeLanguage(lang)
    });
};