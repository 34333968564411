import Swiper from 'swiper';
import { CSSSelector, SwiperOptions } from 'swiper/types';
import { Autoplay, Parallax } from 'swiper/modules';
Swiper.use([Autoplay, Parallax]);
export const SliderMainInit = (selector: CSSSelector | HTMLElement, options: SwiperOptions) => {
    const swiper = new Swiper(selector, {
        ...options
    })
    return swiper;
}

