import './style.css';
import {LanguageSwitcher} from "./switch.language";
// import {scrollInit} from  './scroll.land';
import { SliderMainInit } from './slider.main';
document.addEventListener('DOMContentLoaded', function(){
    SliderMainInit('.slider-main' , {
        autoplay: true,
        speed: 2500,
        loop: true,
        parallax: true,
    });
    LanguageSwitcher();
    // scrollInit();
    
});